@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: infinite 20s linear;
  }
}
/*IPhone x adapter*/
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .fixed-bottom{
  bottom: 37px;
  }
 }
 /*IPhone XS Max adapter*/
 @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio:3) {
  .fixed-bottom{
  bottom: 37px;
  }
 }
 /*IPhone XR Max adapter*/
 @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio:2) {
  .fixed-bottom{
  bottom: 37px;
  }
 }

.fixed-bottom{
  bottom: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
 }

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  padding: 5px;
}

.Social-logo {
  max-width: 20%;  
  height: auto;
  padding: 15px;
}

.App-header {
  background-color: transparent;
  background-image: linear-gradient(#FFC000 0%, #FF8700 100%);
  min-height: 10vh;
}

.App-body {
  background-color: #FFFEE8;
  min-height: 79vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-body-title {
  font-family: 'Bangers', Arial, sans-serif;
  font-size:200%;
}

.App-link {
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-footer {
  background-color: black;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}